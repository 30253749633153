import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./terms.css"
const PrivacyPolicy = () => {
    return (
        <Layout>
            <Seo title="Privacy policy" />
            <div className="terms__wrapper">
                <h3>Privacy policy</h3>
                <p>
                    Date of last update:{" "}
                    <span className="bold">2023-04-20</span>
                </p>
                <p>
                    This Privacy Policy covers how ARAY Society AB collects and
                    processes your Personal Data obtained by us through your use
                    of the Services or otherwise shared by you. Any and all use
                    and processing of your personally identifiable information
                    by ARAY Society is governed by the laws of Sweden and the
                    European Community (EU) and this Privacy Policy.
                </p>
                <p>
                    We keep our Privacy Policy under regular review and may make
                    changes to it. Any changes will be posted on our website
                    and, where appropriate, notified to you. Your continued use
                    of Services will signify your acceptance of the changes to
                    the Privacy Policy.
                </p>
                <p>
                    Delivered in the form of an application, ARAY Society is a
                    digital platform connecting female golfers and inspiring
                    women to play and explore all things golf.
                </p>
                <p>
                    ARAY Society is a controller and responsible for the
                    processing of your Personal Data. We will only process your
                    Personal Data in accordance with this Privacy Policy and
                    applicable laws and regulations.
                </p>
                <ol>
                    <li>
                        <span className="point-title bold">
                            Information we collect
                        </span>
                        <p>
                            When the user creates an Account in order to use the
                            app, ARAY Society asks these users to provide:
                            contact information such as name, e-mail address,
                            birth date, information regarding a user’s golf
                            proficiency (e.g., handicap, rankings, equipment,
                            etc.). ARAY Society may also collect personal data
                            such as IP address, Avatar/Picture, Golf ID,
                            Handicap, Country and City (as base/home location),
                            network quality or information regarding what kind
                            of mobile devices the users have in order to secure
                            the performance of the app.
                        </p>
                        <p>
                            The app will only get the device location
                            information upon the need for a search/query to the
                            data (when the query may require, not all queries
                            need the information). Our app will ask the user to
                            allow such access. The user can block access to the
                            app's location on the device settings. If done, the
                            app will request that access again if needed to
                            fulfil a request in the app done by the user.
                        </p>
                        <p>
                            The users hereby give their consent that ARAY
                            Society geolocalise them if they elect to use the
                            Geolocation Service.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            How the information is collected
                        </span>
                        <p>
                            To provide the best possible service, we may also
                            collect certain information automatically. This
                            includes data related to navigation, user
                            interaction, and other metrics that help us
                            understand how our services are being used. This
                            information is collected in an automated way and is
                            used to improve our services and ensure that it is
                            functioning optimally.
                        </p>
                        <p>
                            Our automated collection methods may include the use
                            of cookies and other tracking technologies. These
                            technologies allow us to gather information about
                            users' interactions with our services, including the
                            pages they visit and the actions they take. This
                            information is used to optimise our services and
                            provide a better experience for our users.
                        </p>
                        <p>
                            Our geolocation services are designed to assist
                            users in identifying events, golf courses, and
                            female golfers in the user’s area. In order to
                            provide these services, our app will only access
                            device location information when it is necessary for
                            a search or query of the data. Not all queries
                            require location information, and our app will only
                            request access to this information upon the user's
                            permission.
                        </p>
                        <p>
                            We respect and acknowledge the user's right to
                            privacy, and we will always ask for their consent
                            before accessing their location information. If the
                            user chooses to block access to their device's
                            location settings, our app will request access again
                            to provide the best possible service.
                        </p>
                        <p>
                            Any location information collected by our app will
                            be used solely for the purpose of providing the
                            requested services. We do not share or sell location
                            information to third parties. We have implemented
                            industry-standard measures to protect against
                            unauthorised access or disclosure of user data.
                        </p>
                        <p>
                            We take our users' privacy very seriously and have
                            implemented industry-standard measures to protect
                            against unauthorised access or disclosure of user
                            data. By using our services, you agree to the terms
                            outlined in this privacy policy.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            How we use the information
                        </span>
                        <p>
                            The data is used to create a user profile on the
                            platform and to ensure you get a good experience in
                            the app, that you can find other female golfers to
                            play with that are on the same level and age (if
                            that matters for you) and location as you are. We
                            also want to give you suggestions of events nearby,
                            golf courses for you to experience and other female
                            golfers for you to connect with. The email address
                            is used to give you notifications of new happenings
                            or information about ARAY Society and your new
                            female golf network. The usage information data is
                            used for us to improve the app’s user experience,
                            content and design.
                        </p>
                        <p>
                            ARAY Society uses the personal data collected for
                            the following purposes:
                        </p>
                        <ul>
                            <li>
                                Identification and authentication of the user
                                when accessing your account;
                            </li>
                            <li>
                                Enabling access to and use of the app by the
                                user Data Subjects
                            </li>
                            <li>
                                Billing purposes for events, trips, tournaments
                                and other activities within the app;
                            </li>
                            <li>Improving the quality of the platform;</li>
                            <li>
                                Market research purposes, e.g., measurements of
                                traffic and usage trends;
                            </li>
                            <li>
                                Marketing and advertising purposes in the
                                conditions set out in section 4 below.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className="point-title bold">
                            Marketing preferences
                        </span>
                        <p>
                            ARAY Society may use the personal data to discuss
                            the services available in the app or to send the
                            user information concerning ARAY Society and/or
                            events, tournaments or golf trips the user has
                            signed up for or promotional activities via
                            newsletters and letters to the e-mail address
                            provided by the user.
                        </p>
                        <p>
                            ARAY Society may also use the user’s personal data
                            in the app for the purpose of providing them with
                            commercial communications of offers and/or events
                            from third parties that are in business relations
                            with ARAY Society in the app.
                        </p>
                        <p>
                            By accepting this Privacy Policy, the user accepts
                            that ARAY Society uses the e-mail address or
                            telephone number provided by the Data Subjects in
                            order to contact the Data Subjects for the purposes
                            above.
                        </p>
                        <p>
                            The user may opt out of being contacted by ARAY
                            Society at any time by using the opt-out link
                            provided in any newsletter sent to the user or by
                            contacting ARAY Society in this regard at the
                            contact details given in section 8 “Contacts”.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            Our protection of your data
                        </span>
                        <p>
                            We recognize the importance of safeguarding all
                            personal data collected from our users. Therefore,
                            we have implemented measures to ensure that your
                            information is protected throughout its lifecycle.
                        </p>
                        <p>
                            All personal data collected through our platform is
                            divided into two distinct categories: sensitive
                            personal data and analytical data. Sensitive
                            personal data is kept isolated from analytical data,
                            and is treated with the utmost confidentiality and
                            security. Access to this data is restricted to only
                            those personnel who require it for legitimate
                            purposes.
                        </p>
                        <p>
                            Analytical data is used solely for statistical
                            analysis, and cannot be connected to you as an
                            individual in any way, at any time. This data is
                            collected automatically when you interact with our
                            platform, and includes information such as your
                            device type, browser type, and IP address. We use
                            this data to improve the performance and
                            functionality of our platform, and to provide a
                            better user experience for you.
                        </p>
                        <p>
                            We do not share your personal data with any third
                            parties, except as required by law or as necessary
                            to fulfil our obligations to you. We will retain
                            your personal data only for as long as necessary to
                            fulfil the purposes for which it was collected, or
                            as required by applicable law. Once your data is no
                            longer needed, we will securely dispose of it in a
                            manner that ensures it cannot be accessed or used by
                            unauthorised personnel.
                        </p>
                        <p>
                            If you have any questions or concerns regarding our
                            privacy practices, please contact us using the
                            contact information provided in this privacy policy.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            Third-party data sharing
                        </span>
                        <p>
                            We may share analytical data with third-party
                            service providers to help us operate, improve and
                            get insights about our platform. However, we will
                            only share essential data necessary for the
                            third-party to provide their services, and we will
                            not share sensitive personal data at any point in
                            time.
                        </p>
                        <p>
                            We take great care to ensure that any third-party
                            service providers we work with comply with industry
                            standards for data privacy and security. We require
                            that these providers treat your data with the same
                            level of confidentiality and security as we do.
                        </p>
                        <p>
                            Any analytical data shared with third-party
                            providers is used solely for statistical analysis
                            and cannot be connected to you as an individual in
                            any way, at any time. This data includes information
                            such as your device type, browser type, and IP
                            address.
                        </p>
                        <p>
                            We do not sell your personal data to any third
                            parties, and we do not permit any third parties to
                            use your data for their own marketing purposes.
                        </p>
                        <p>
                            Trips, tournaments and events arranged by ARAY
                            Society in collaboration with other partners such as
                            golf clubs, golf brands etc. When a user signs up
                            for these kinds of activities in the app the
                            third-party will have access to the personal data
                            the user discloses for the purposes of managing the
                            activity and contact the user about activity
                            details.
                        </p>
                        <p>
                            ARAY Society may provide URL links towards
                            third-party websites. ARAY Society is not
                            responsible for the manner in which these
                            third-party websites handle any Personal Data that
                            may be disclosed to them. The user is invited to
                            refer to the appropriate third-party website’s
                            privacy policy in order to verify how their personal
                            data is used by such third-party website.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">User rights</span>
                        <p>
                            As a user you can update or correct any of your
                            Personal Data at any time by logging into your
                            Account and editing your personal information. All
                            users are entitled to request ARAY Society to
                            provide the user, free of charge, with a list of all
                            of the user’s Personal Data stored by ARAY Society
                            by sending an e-mail in this regard to the following
                            e-mail address: info@araysociety.com. ARAY Society
                            undertakes to provide the user with such a list
                            within a reasonable timeframe. A user can ask to
                            delete her account and thereof also her personal
                            data from ARAY Society at any time through emailing
                            info@araysociety.com.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">Contacts</span>
                        <p>
                            We have appointed a Data Protection Officer. If you
                            have any questions or comments about this Privacy
                            Policy or our processing of your Personal Data,
                            please reach out to our customer support at
                            info@araysociety.com.
                        </p>
                        <div className="contact-details">
                            <p className="bold">Contacts details:</p>
                            <p>ARAY Society AB</p>
                            <p>Reg.no 559327-8772</p>
                            <p>Tyska Skolgränd 4</p>
                            <p>111 31 Stockholm, Sweden</p>
                        </div>
                    </li>
                </ol>
            </div>
        </Layout>
    )
}

export default PrivacyPolicy
